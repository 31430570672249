<template>
  <BCard class="min-h-[100vh]">
    <div class="flex w-full justify-between items-center">
      <div class="flex gap-[12px]">
        <BButton
          variant="primary"
          size="sm"
          class="rounded-[6px]"
          @click="$router.back()"
        >
          <img
            class="w-[20px]"
            src="https://storage.googleapis.com/komerce/assets/komerce-icon/Putih/arrow-left-2.svg"
            alt="arrow left"
          >
        </BButton>
        <div class="text-[#333333] font-semibold text-[24px]">
          Detail Partner
        </div>
      </div>
      <BButton
        variant="primary"
        size="md"
        class="rounded-[6px]"
        @click="pushToSyncTransaction"
      >
        <div class="flex gap-[5px] items-center">
          <span class="k-clock-1" />
          <div>
            Riwayat Sync
          </div>
        </div>
      </BButton>
    </div>
    <div class="flex items-end justify-between">
      <div class="border mt-8 gap-[12px] text-[#333333] p-1 rounded-xl">
        <div class="flex items-center gap-7">
          <img
            class="bg-[#F8F8F8] rounded-full p-[12px] text-[14px]"
            src="https://storage.googleapis.com/komerce/assets/komerce-icon/Hitam/user.svg"
            alt="human"
          >
          <div>
            <div class="font-semibold">
              {{ user.name }}
            </div>
            <div>
              {{ user.email }}
            </div>
          </div>
        </div>
        <div class="bg-[#F8F8F8] rounded-lg mt-1 p-[8px]">
          <div class="text-[#626262] font-medium">
            Total Cards : {{ user.total_card }} Cards
          </div>
        </div>
      </div>
      <BCol
        lg="5"
        class="d-flex rounded-lg align-items-center border search-bar"
      >
        <span
          class="k-search-normal-1 h-100 font-bold align-middle mx-[5px]"
          style="font-size: 20px"
        />
        <BFormInput
          v-model="search"
          placeholder="Search card name / 4 last card number"
          class="border-0"
          @input="searchData"
        />
      </BCol>
    </div>
    <BOverlay
      :show="loadingTable"
      spinner-variant="primary"
      variant="light"
      class="mt-[16px]"
      blur="0"
      opacity=".5"
      rounded="sm"
    >
      <div
        id="table"
        class="mt-1"
        style="height: calc(100vh - 250px); overflow-y: scroll;"
        @scroll="handleScroll"
      >
        <BTable
          :items="items"
          :fields="fields"
          show-empty
          empty-text="Tidak ada data"
          responsive
        >
          <template #cell(card_name)="data">
            <div class="flex items-center gap-8">
              <span class="k-card text-[#C2C2C2] text-[20px]" />
              <div class="flex flex-col">
                <div class="text-[14px] gap-[4px] flex items-center">
                  <span class="text-[#333333] font-semibold text-nowrap">
                    {{ data.item.label }}
                  </span>
                  <div class="text-[#626262]">
                    {{ `***${data.item.last_number}` }}
                  </div>
                </div>
                <div class="text-[#828282]">
                  Created at {{ DAY_MONTH_YEAR(data.item.created_at) }}
                </div>
              </div>
            </div>
          </template>
          <template #cell(limit_day)="data">
            <span class="text-[#828282] text-[14px] text-nowrap">
              {{ IDR(data.item.daily_limit, 2, 2) }}
            </span>
          </template>
          <template #cell(balance_card)="data">
            <span class="text-[#828282] text-[14px] text-nowrap">
              {{ IDR(data.item.balance, 2, 2) }}
            </span>
          </template>
          <template #cell(balance_voucher)="data">
            <span class="text-[#D19D04] text-[14px] text-nowrap">
              {{ '+' + IDR(data.item.voucher_balance, 2, 2) }}
            </span>
          </template>
          <template #cell(balance_total)="data">
            <span class="text-[#333333] text-[14px] text-nowrap">
              {{ IDR(data.item.balance + data.item.voucher_balance, 2, 2) }}
            </span>
          </template>
          <template #cell(status)="data">
            <span
              v-if="data.item.status === 'active'"
              class="text-[#333333] flex text-[14px] text-nowrap gap-[4px] items-center justify-center"
            >
              <div class="bg-[#34A770] h-[8px] w-[8px] rounded-full" />
              <div>Aktif</div>
            </span>
            <span
              v-if="data.item.status === 'inactive'"
              class="text-[#333333] flex text-[14px] text-nowrap gap-[4px] items-center justify-center"
            >
              <div class="bg-[#E31A1A] h-[8px] w-[8px] rounded-full" />
              <div>Tidak Aktif</div>
            </span>
            <span
              v-if="data.item.status === 'pending'"
              class="text-[#333333] flex text-[14px] text-nowrap gap-[4px] items-center justify-center"
            >
              <div class="bg-[#c2c2c2] h-[8px] w-[8px] rounded-full" />
              <div>Pending</div>
            </span>
          </template>
          <template #cell(more)="data">
            <span class="flex justify-center">
              <BDropdown
                variant="none"
                no-caret
                size="sm"
                offset="-100"
                block
                menu-class="font-medium mb-[-30px]"
                @show="
                  handleOpenOption({
                    id: data.item.id,
                    last_number: data.item.last_number,
                    name: data.item.label,
                    card_id: data.item.card_uuid,
                    balance: data.item.daily_limit,
                  })
                "
              >
                <template #button-content>
                  <button class="bg-transparent">
                    <img
                      src="https://storage.googleapis.com/komerce/assets/komerce-icon/Hitam/more.svg"
                      alt="more-icon"
                    >
                  </button>
                </template>
                <div class="w-[200px]">
                  <div
                    :class="
                      data.item.status === 'active'
                        ? 'cursor-pointer text-[#626262] hover:bg-[#f8f8f8]'
                        : 'text-[#c2c2c2]'
                    "
                    class="flex gap-[3px] py-[5px] items-center px-[4px] text-[14px]"
                    @click="handleDailyLimit(data.item.status)"
                  >
                    <span
                      class="k-card-edit text-[20px]"
                      alt="edit"
                    />
                    <div class="text-nowrap ml-[4px]">Ubah Limit Harian</div>
                  </div>
                  <div
                    class="flex py-[5px] cursor-pointer gap-[3px] items-center px-[4px] hover:bg-[#f8f8f8] text-[#626262] text-[14px]"
                    @click="
                      handleCompareBalance({
                        id: data.item.id,
                        last_number: data.item.last_number,
                        name: data.item.label,
                        card_id: data.item.card_uuid,
                      })
                    "
                  >
                    <span
                      class="k-eye text-[20px]"
                      alt="eye"
                    />
                    <div class="text-nowrap ml-[4px]">Cek Selisih Saldo</div>
                  </div>
                  <div
                    class="flex gap-[3px] py-[5px] cursor-pointer items-center px-[4px] hover:bg-[#f8f8f8] text-[#626262] text-[14px] "
                    @click="
                      $router.push(
                        '/komcards-data-partner/mutation/' + data.item.id
                      )
                    "
                  >
                    <span
                      class="k-diagram text-[20px]"
                      alt="diagram"
                    />
                    <div class="text-nowrap ml-[4px]">Lihat Mutasi</div>
                  </div>
                  <div
                    class="flex gap-[3px] py-[5px] cursor-pointer items-center px-[4px] hover:bg-[#f8f8f8] text-[#626262] text-[14px] "
                    @click="syncTransaction(data.item.id)"
                  >
                    <span
                      class="k-clipboard-export text-[20px]"
                      alt="diagram"
                    />
                    <div class="text-nowrap ml-[4px]">Sync Transaksi</div>
                  </div>
                </div>
              </BDropdown>
            </span>
          </template>
        </BTable>
      </div></BOverlay>
    <ModalDailyLimit
      :id="activeChooseId.id"
      :name="activeChooseId.name"
      :last-number="activeChooseId.lastNumber"
      :daily-limit="activeChooseId.daily_limit"
      @close="handleCloseModalDailyLimit"
    />
    <ModalCompareBalance
      :balance="activeCompareBalance.balance"
      :diff_balance="activeCompareBalance.diff_balance"
      :card_id="activeCompareBalance.card_id"
      :hold_balance="activeCompareBalance.hold_balance"
      :last_number="activeCompareBalance.last_number"
      :name="activeCompareBalance.name"
      :monit_balance="activeCompareBalance.monit_balance"
      :voucher_balance="activeCompareBalance.voucher_balance"
    />
  </BCard>
</template>

<script>
import { newAxiosIns } from '@/libs/axios'
import { IDR } from '@/libs/currency'
import ToastificationContentVue from '@/@core/components/toastification/ToastificationContent.vue'
import isEmpty from 'lodash/isEmpty'
import { DAY_MONTH_YEAR } from '@/libs/filterDate'
import ModalDailyLimit from './ModalDailyLimit.vue'
import ModalCompareBalance from './ModalCompareBalance.vue'
import columnConfig from './config'

export default {
  components: {
    ModalDailyLimit,
    ModalCompareBalance,
  },
  data() {
    return {
      DAY_MONTH_YEAR,
      loadingTable: false,
      lastData: false,
      items: null,
      IDR,
      offset: 0,
      limit: 25,
      search: '',
      fields: columnConfig,
      activeChooseId: {
        name: null,
        lastNumber: null,
        id: null,
        daily_limit: null,
      },
      user: {
        name: null,
        email: null,
        total_card: null,
      },
      activeCompareBalance: {
        balance: null,
        hold_balance: null,
        monit_balance: null,
        voucher_balance: null,
        name: null,
        last_number: null,
        card_id: null,
        diff_balance: null,
      },
    }
  },
  mounted() {
    this.getList()
    this.getUser()
  },

  methods: {
    pushToSyncTransaction() {
      this.$router.push(
        `/komcards-data-partner/sync-transaction/${this.$route.params.id}`,
      )
    },
    async syncTransaction(id) {
      const url = `komcards/api/v1/card/${id}/sync`

      try {
        const data = await newAxiosIns.request({
          method: 'post',
          url,
          params: {
            card_id: id,
          },
        })

        if (data.status === 200) {
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: 'Berhasil',
              icon: 'CheckIcon',
              text: 'Proses synchronize transaksi sedang berlangsung',
              variant: 'success',
            },
          }, { timeout: 3000 })
          return
        }
      } catch (error) {
        const meta = JSON.parse(error.request.response)

        if (meta.meta.message === 'pending process found, cannot proceed') {
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: 'Kesalahan',
              icon: 'XCircleIcon',
              text: 'Tidak bisa melakukan synchronize transaksi sekarang, karena ada proses yang berlangsung',
              variant: 'danger',
            },
          }, { timeout: 3000 })
          return
        }

        this.$toast_error({ message: 'Gagal sync data kartu' })
      }
    },
    convertDesimal(value) {
      return new Intl.NumberFormat('id-ID', {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      }).format(value)
    },
    async handleCloseModalDailyLimit() {
      this.offset = 0
      this.getList()
    },
    getNextData() {
      const { id } = this.$route.params

      if (!this.lastData) {
        this.loadingTable = true
        const params = {
          user_id: id,
          offset: this.offset,
          limit: this.limit,
          search: this.search,
        }

        const url = 'komcards/api/v1/admin/card/list'

        newAxiosIns
          .get(url, { params })
          .then(res => {
            const { data } = res.data
            const normalizeData = !isEmpty(data) ? data : []
            this.items.push(...normalizeData)
            this.offset += data.length
            this.loadingTable = false
            if (data.length < this.limit) {
              this.lastData = true
            }
          })
          .catch(() => {
            this.loadingTable = false
            this.$toast_error({ message: 'Gagal load data' })
          })
      }
    },
    searchData: _.debounce(function search() {
      this.getList()
    }, 1000),
    handleOpenOption(objectProps) {
      const {
        id, last_number, name, balance,
      } = objectProps

      this.activeChooseId.id = id
      // eslint-disable-next-line camelcase
      this.activeChooseId.lastNumber = last_number
      this.activeChooseId.name = name
      this.activeChooseId.daily_limit = balance
    },
    handleScroll() {
      const table = document.getElementById('table')
      if (
        table.scrollTop >= table.scrollHeight - table.offsetHeight - 5
        && !this.loadingTable
        && !this.lastData
      ) {
        this.getNextData()
      }
    },
    async getUser() {
      const { id } = this.$route.params
      const url = `auth/api/v1/admin/user/${id}/info`

      try {
        const data = await newAxiosIns.request({
          method: 'get',
          url,
        })

        if (data.status === 200) {
          const { name, email, total_card } = data.data.data
          this.user = {
            name,
            email,
            total_card,
          }
          return
        }
      } catch (error) {
        this.$toast_error({ message: 'Gagal load data user' })
      }
    },
    handleDailyLimit(status) {
      if (status === 'active') {
        this.$bvModal.show('modal-limit-daily')
      }
    },
    async handleCompareBalance(objectProps) {
      const {
        id, last_number, name, card_id,
      } = objectProps

      const url = `komcards/api/v1/card/${id}/info-balance`

      try {
        const data = await newAxiosIns.request({
          method: 'get',
          url,
        })

        if (data.status === 200) {
          this.activeCompareBalance = data.data.data

          this.activeCompareBalance = {
            id,
            last_number,
            name,
            card_id,
            ...this.activeCompareBalance,
          }

          this.$bvModal.show('modal-compare-balance')

          return
        }
      } catch (error) {
        this.$toast_error({ message: 'Gagal load data' })
        return
      }

      this.$bvModal.show('modal-compare-balance')
    },
    async getList() {
      this.loadingTable = true
      const { id } = this.$route.params
      this.offset = 0

      const params = {
        user_id: id,
        offset: this.offset,
        limit: this.limit,
        search: this.search,
      }

      const url = 'komcards/api/v1/admin/card/list'

      await newAxiosIns
        .get(url, { params })
        .then(res => {
          const { data } = res.data
          const normalizeData = !isEmpty(data) ? data : []
          this.loadingTable = false
          this.offset = normalizeData.length
          this.items = normalizeData
          if (normalizeData.length < this.limit) {
            this.lastData = true
          } else {
            this.lastData = false
          }
        })
        .catch(() => {
          this.$toast_error({ message: 'Gagal load data' })
          this.loadingTable = false
        })
    },
    handleResetLimit() {
      this.activeChooseId = {}
    },
  },
}
</script>

<style scoped></style>
