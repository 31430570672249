<template>
  <BModal
    id="modal-compare-balance"
    ref="modal-compare-balance"
    hide-footer
    hide-header
    centered
  >
    <div class="px-[32px] py-[56px]">
      <div class="text-center text-[24px] font-semibold text-[#333333]">
        Cek Selisih Saldo
      </div>
      <div
        :class="
          diff_balance !== 0
            ? 'border-[#FBA63C] bg-[#FFF8E1]'
            : 'bg-[#DCF3EB] border-[#34A770]'
        "
        class="mt-[24px]  p-[12px] rounded-[8px]  border-[1px]"
      >
        <div
          class="flex items-center  gap-[8px] font-semibold text-[#626262] text-[#626262]"
        >
          <img
            v-if="diff_balance === 0"
            src="https://storage.googleapis.com/komerce/assets/icons/Checklist-fill.svg"
            class="h-[20px]"
            alt="info"
          >
          <img
            v-if="diff_balance !== 0"
            class="h-[20px]"
            src="https://storage.googleapis.com/komerce/assets/svg/warning-Info-circle.svg"
            alt="info"
          >
          <div v-if="diff_balance === 0">
            Tidak ada selisih saldo
          </div>
          <div v-if="diff_balance !== 0">
            Terdeteksi selisih saldo
          </div>
        </div>
      </div>
      <div class="flex justify-between mt-[24px] items-center">
        <div class="text-[#626262] text-[16px] font-semibold">
          Informasi Saldo
        </div>
        <div
          class="border-[2px] py-[3px] px-[8px] cursor-pointer  flex items-center gap-[2px] border-[#F95031] rounded-[4px]"
          @click="handleCopy"
        >
          <div class="text-[#F95031] text-[12px] font-bold ">
            Salin Data
          </div>
          <img
            class="h-[12px]"
            src="https://storage.googleapis.com/komerce/assets/komerce-icon/Orange/copy.svg"
            alt="salin"
          >
        </div>
      </div>
      <div
        class="flex justify-between mt-[24px] items-center px-[12px] py-[6px] bg-[#F8F8F8] rounded-[8px]"
      >
        <div class="flex gap-[5px] items-center">
          <img
            src="https://storage.googleapis.com/komerce/assets/komerce-icon/Hitam/card.svg"
            alt="card"
          >
          <div class="font-semibold text-[#333333]">
            {{ name }}
          </div>
        </div>
        <div class="text-[#626262] text-[14px]">
          ***{{ last_number }}
        </div>
      </div>
      <div
        class="flex flex-column gap-[16px] mt-[24px] pt-[12px] rounded-[8px] border-[1px] border-[#E2E2E2]"
      >
        <div class="flex  justify-between items-center text-[16px] mx-[12px]">
          <div class="text-[#626262] ">
            Saldo Komcards
          </div>
          <div class="text-[#333333]">
            {{ IDR(balance, 2, 2) }}
          </div>
        </div>
        <div
          :key="data"
          class="flex  justify-between items-center text-[16px] mx-[12px]"
        >
          <div class="text-[#626262] ">
            Saldo Monit
          </div>
          <div class="text-[#333333]">
            {{ IDR(monit_balance, 2, 2) }}
          </div>
        </div>
        <div
          :key="data"
          class="flex  justify-between items-center text-[16px] mx-[12px]"
        >
          <div class="text-[#626262] flex gap-[4px]">
            <div>
              Saldo Pending
            </div>
            <img
              v-b-tooltip.hover.topright
              title="Saldo pending yang berada di Monit"
              height="15"
              src="https://storage.googleapis.com/komerce/assets/icons/info-circle.svg"
              alt="info"
            >
          </div>
          <div class="text-[#333333]">
            {{ IDR(hold_balance, 2, 2) }}
          </div>
        </div>
        <div
          class="flex  bg-[#F8F8F8] border-t-[1px] border-[#E2E2E2] py-[12px] border-dashed w-full justify-between px-[12px] items-center text-[16px]"
        >
          <div class="text-[#333333] font-semibold flex gap-[4px]">
            <div>Selisih Saldo</div>
            <img
              v-b-tooltip.hover.topright
              title="Selisih saldo antara saldo Komcards dengan saldo Monit"
              height="15"
              src="https://storage.googleapis.com/komerce/assets/icons/info-circle.svg"
              alt="info"
            >
          </div>
          <div class="text-[#333333] font-semibold">
            {{ IDR(Math.abs(diff_balance),2,2) }}
          </div>
        </div>
      </div>
      <BButton
        variant="primary"
        class="w-full mt-[24px]"
        @click="$bvModal.hide('modal-compare-balance')"
      >
        Tutup
      </BButton>
    </div>
  </BModal>
</template>
<script>
/* eslint-disable vue/prop-name-casing */

import { IDR } from '@/libs/currency'
import { alertSuccess } from '@toast'

export default {
  props: {
    balance: {
      default: null,
      type: [String, Number],
    },
    hold_balance: {
      default: null,
      type: [String, Number],
    },
    monit_balance: {
      default: null,
      type: [String, Number],
    },
    voucher_balance: {
      default: null,
      type: [String, Number],
    },
    name: {
      default: null,
      type: [String, Number],
    },
    last_number: {
      default: null,
      type: [String, Number],
    },
    card_id: {
      default: null,
      type: [String, Number],
    },
    diff_balance: {
      default: null,
      type: [String, Number],
    },
  },
  data() {
    return {
      IDR,
      alertSuccess,
    }
  },
  methods: {
    convertDesimal(value) {
      return new Intl.NumberFormat('id-ID', {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      }).format(value)
    },
    handleCopy() {
      const text = `Nama Kartu: ${this.name}\n4 Digit Terakhir: ${
        this.last_number
      }\nCard_Id: ${this.card_id}\nBalance Komcards: ${this.convertDesimal(
        this.balance,
      )}\nBalance Monit: ${this.convertDesimal(
        this.monit_balance,
      )}\nSaldo Pending: ${this.convertDesimal(
        this.hold_balance,
      )}\n*Selisih Saldo: ${this.convertDesimal(Math.abs(this.diff_balance), 2, 2)}*`
      navigator.clipboard.writeText(text)
      this.alertSuccess('Berhasil menyalin')
    },
  },
}
</script>

<style scoped></style>
/* eslint-disable-line */
