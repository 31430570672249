<!-- eslint-disable vue/require-prop-types -->
<template>
  <BModal
    id="modal-limit-daily"
    ref="modal-limit-daily"
    hide-footer
    hide-header
    centered
    @show="showModal"
    @hide="hideModal"
  >
    <div class="px-[32px] py-[56px]">
      <div class="flex justify-center">
        <img
          src="https://storage.googleapis.com/komerce/core/icon-popup-warning.png"
          alt="warning"
        >
      </div>
      <div
        class="text-[#333333] text-center text-[24px] font-semibold mt-[24px]"
      >
        Ubah Limit Harian
      </div>
      <div class="text-[#333333] text-center text-[16px] mt-[16px]">
        Silahkan ubah limit harian untuk kartu :
      </div>
      <div class="flex justify-center mt-[16px]  ">
        <div
          class="flex text-[14px] justify-center gap-[8px] items-center w-fit px-[30px] px-[24px] py-[8px] bg-[#F8F8F8] rounded-[12px]"
        >
          <img
            src="https://storage.googleapis.com/komerce/assets/komerce-icon/Hitam/card.svg"
            alt="card"
          >
          <div class="text-nowrap font-semibold text-[#333333]">
            {{ name }}
          </div>
          <div class="text-[#626262]">
            ***{{ lastNumber }}
          </div>
        </div>
      </div>
      <div
        v-if="limit !== null"
        class="mt-[24px]"
      >
        <BInput
          v-model="limit"
          placeholder="cth : Rp20.000.000"
          @keypress="handleKeyPress"
          @input="inputLimit"
        />
        <small class="text-danger">{{ errorLimit }}</small>
      </div>
      <div class="grid grid-cols-2 mt-[24px] gap-[12px]">
        <BButton
          variant="outline-primary"
          @click="closeButton"
        >
          Batal
        </BButton>
        <BButton
          :id="
            errorLimit !== null || realNumber() === dailyLimit
              ? 'submit-btn'
              : ''
          "
          :disabled="errorLimit !== null || realNumber() === dailyLimit"
          :variant="
            errorLimit !== null || realNumber() === dailyLimit ? '' : 'primary'
          "
          @click="submitChangeLimit"
        >Simpan</BButton>
      </div>
    </div>
  </BModal>
</template>

<script>
import { alertError, alertSuccess } from '@toast'
import { newAxiosIns } from '@/libs/axios'

export default {
  props: {
    name: {
      default: null,
      type: String,
    },
    lastNumber: {
      default: null,
      type: String,
    },
    // eslint-disable-next-line vue/require-prop-types
    id: {
      default: null,
    },
    dailyLimit: {
      default: null,
      type: Number,
    },
  },
  data() {
    return {
      errorLimit: null,
      limit: 0,
      stateless: null,
      alertError,
      alertSuccess,
    }
  },
  methods: {
    hideModal() {
      this.limit = null
      this.errorLimit = null
    },
    realNumber() {
      return parseInt(`${this.limit}`.replace(/[Rp.p]/g, ''), 10)
    },
    inputLimit(value) {
      const realNumber = parseInt(value.replace(/[Rp.p]/g, ''), 10)

      if (Number.isNaN(realNumber)) {
        this.errorLimit = 'Nominal tidak Valid'
      } else if (realNumber <= 0 || realNumber === null) {
        this.errorLimit = 'Nominal tidak boleh kosong'
      } else if (realNumber === this.dailyLimit) {
        this.errorLimit = 'Nominal tidak boleh sama dari limit sebelumnya'
      } else if (realNumber < 10000) {
        this.errorLimit = 'Batas pengeluaran tidak boleh kurang dari Rp10.000'
      } else if (realNumber > 999999999) {
        this.errorLimit = 'Batas pengeluaran tidak boleh lebih dari Rp999.999.999'
      } else {
        this.errorLimit = null
      }

      this.limit = this.convertToIdr(value)
    },
    closeButton() {
      this.limit = null
      this.$bvModal.hide('modal-limit-daily')
    },
    convertToIdr(value) {
      const currency = `${value}`.replace(/[Rp. ]/g, '')
      const format = new Intl.NumberFormat('id-ID').format(currency)
      return `Rp ${format}`
    },
    showModal() {
      this.limit = this.convertToIdr(this.dailyLimit)
    },
    handleKeyPress(e) {
      if (e.keyCode < 48 || e.keyCode > 57) {
        e.preventDefault()
      }
    },
    async submitChangeLimit() {
      const realNumber = parseFloat(this.limit.replace(/[Rp.p]/g, ''))

      const url = `komcards/api/v1/card/${this.id}/set-limit`

      this.errorLimit = null
      try {
        const data = await newAxiosIns.request({
          method: 'PUT',
          url,
          data: {
            limit: realNumber,
          },
        })
        if (data.status === 200) {
          this.$emit('close')

          this.alertSuccess('Data berhasil diubah')
          this.$bvModal.hide('modal-limit-daily')

          //  refect
          return
        }

        throw new Error()
      } catch (error) {
        this.$toast_error({ message: 'Gagal mengubah limit harian' })
      }

      this.$bvModal.hide('modal-limit-daily')
    },
  },
}
</script>

<style scoped>
#submit-btn {
  background-color: #f4f4f4 !important;
  border-color: #e2e2e2 !important;
  color: #c2c2c2 !important;
}
</style>
